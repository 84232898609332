<template>
    <div>
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>

        <ConfirmDialog header="Confirm operation"
                       question="Do you want to get workers data from SAP system?"
                       v-model="isConfirmationGetSapWorkersOpen"
                       :operation="actionGetSapWorkersButton" />

        <ConfirmDialog header="Confirm operation"
                       question="Do you want to create user accounts for selected workers?"
                       v-model="isConfirmationAddToUsersOpen"
                       :operation="actionAddToUsersButton"
                       :parameter="recordSelectedToPass" />

        <ConfirmDialog header="Confirm operation"
                       question="Do you want to approve assesses sheets?"
                       v-model="isConfirmationApproveSheetsOpen"
                       :operation="approveSheets"
                       :parameter="approveSheetsParameter" />

        <ConfirmDialog header="Confirm operation"
                       question="Do you want to pass to approve assesses sheets?"
                       v-model="isConfirmationPassToApproveSheetsOpen"
                       :operation="passToApproveSheets"
                       :parameter="passToApproveSheetsParameter" />

        <ConfirmDialog header="Confirm operation"
                       question="Do you want to pass to accept assesses sheets?"
                       v-model="isConfirmationPassToAcceptSheetsOpen"
                       :operation="passToAcceptSheets"
                       :parameter="passToAcceptSheetsParameter" />


        <Dialog modal
                :visible="isGenerateSheetsModalOpen"
                v-model:visible="isGenerateSheetsModalOpen"
                :header="__('Generate sheets')"
                :style="{ width: '500px' }">
            <p style="padding-bottom:15px;font-size:16px; font-weight:bold;color:cadetblue">{{ __('Sheets will be generated for selected premium group') }}</p>

            <GenerateSheetsForm :formProps="generateSheetsFormProps"
                                v-model:isGenerateSheetsModalOpen="isGenerateSheetsModalOpen">
            </GenerateSheetsForm>
        </Dialog>

        <Dialog modal
                :visible="whoAmICountingOpen"
                v-model:visible="whoAmICountingOpen"
                :header="__('Who I am counting?')"
                :style="{ width: '600px' }">
            <p style="padding-bottom:15px;font-size:16px; font-weight:bold;color:cadetblue">{{ __('Information about counting way of workers and sheets') }}</p>
            <div style="padding: 5px 0">
                <div v-html="interpretedSheetAndWorkersCounting"
                     style="height:300px;overflow-y: auto;">
                </div>
            </div>
            <Button severity="secondary"
                    style="margin:10px 8px"
                    @click="whoAmICountingOpen = false">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Close') }}
            </Button>

        </Dialog>

        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :routes="routes"
                :initialSort="initialSort"
                :renderColumnValue="renderColumnValue"
                :renderColumnAction="renderColumnAction"
                :rowButtonAction="rowButtonAction"
                :selectCheckboxConditions="selectCheckboxConditions"
                :buttonsVisibility="buttonsVisibility">
            <template #buttons="formProps">
                <div>
                    <Button @click.stop="whoAmICounting()"
                            style="background-color:#009FD6;margin:0 7px 0 25px;white-space:nowrap"
                            v-tooltip.top="__('Who I am counting?')"
                            severity="info">
                        <i class="fa-solid fa-question"
                           style="margin-right:5px;font-size:18px"></i>
                    </Button>
                </div>
                <div v-if="canGetSapWorkers">
                    <Button @click.stop="openConfirmationGetSapWorkersWindow(formProps)"
                            style="background-color:#009FD6;color:#fff;margin:0 7px 0 25px;white-space:nowrap"
                            severity="info">
                        <i class="fa-solid fa-users-viewfinder"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Get SAP workers') }}
                    </Button>
                </div>
                <div v-if="canCreateUsers">
                    <Button @click.stop="openConfirmationAddToUserWindow(formProps)"
                            style="background-color:#009FD6;color:#fff;margin:0 7px 0 25px;white-space:nowrap"
                            severity="info">
                        <i class="fa-solid fa-users-gear"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Create users') }}
                    </Button>
                </div>
                <div v-if="canGenerateSheets">
                    <Button @click.stop="openGenerateSheetsWindow(formProps)"
                            style="background-color:#009FD6;color:#fff;margin:0 7px 0 0;white-space:nowrap"
                            severity="info">
                        <i class="fa-solid fa-sheet-plastic"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Generate sheets') }}
                    </Button>
                </div>

                <div v-if="canApproveSheets">
                    <Button @click.stop="openConfirmationApproveSheetsWindow(formProps)"
                            style="background-color:#009FD6;color:#fff;margin:0 7px 0 0;white-space:nowrap"
                            severity="info">
                        <i class="fa-regular fa-thumbs-up"
                           style="margin-right:5px;font-size:18px;"></i>
                        {{ __('Approve sheets') }}
                    </Button>
                </div>

                <div v-if="canPassToApproveSheets">
                    <Button @click.stop="openConfirmationPassToApproveSheetsWindow(formProps)"
                            style="background-color:#009FD6;color:#fff;margin:0 7px 0 0;white-space:nowrap"
                            severity="info">
                        <i class="fa-solid fa-pen-fancy"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Pass sheets to approve') }}
                    </Button>
                </div>

                <div v-if="canPassToAcceptSheets">
                    <Button @click.stop="openConfirmationPassToAcceptSheetsWindow(formProps)"
                            style="background-color:#009FD6;color:#fff;margin:0 7px 0 0;white-space:nowrap"
                            severity="info">
                        <i class="fa-solid fa-check-to-slot"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Pass sheets to accept') }}
                    </Button>
                </div>

                <div>
                    <FormCalendar fieldName="workers_on_day"
                                  label="Workers on day"
                                  outerStyle="display:flex;width:300px;justify-content:space-between;align-items:flex-start;"
                                  style="position:relative;top:-7px"
                                  labelStyle="font-size: 14px; width:280px !important; font-weight: normal; color: red; margin-right: 5px;"
                                  :formProps="formProps"
                                  :minDate="new Date(minDate)"
                                  :maxDate="new Date()"
                                  :manualInput="false"
                                  :dateSelect="(e) => workersOnDayChanged(e, formProps)"
                                  :clearClick="(e) => workersOnDayCleared(e, formProps)"
                                  :onMounted="() => workersOnDayInit(formProps)"
                                  v-model:field="workers_on_day" />
                </div>
                <div>
                    <FormInputToggleButton fieldName="show_active_workers"
                                           :label="__('Only important')"
                                           style="display:flex;width:auto;justify-content:space-between;align-items:flex-start;margin-top:-9px;"
                                           labelStyle="font-size: 14px; font-weight: normal; color: #1A83AD; margin:7px 15px 0px 20px;"
                                           v-tooltip.top="__('Show only working and premium subjected workers')"
                                           :change="(e) => setWorkersViewState(e, formProps)"
                                           v-model:field="show_active_workers" />
                </div>

            </template>
            <template #informations>
                <div class='flex gap-2 flex-wrap:nowrap view-header-information'
                     style="margin-left:100px;margin-top:5px">
                    <span v-if="averageAssessments?.averageMonth > 0"
                          class="flex gap-2">
                        <div style="color:green;">{{ __('Average assessment per month') }}:</div>
                        <div style="color:red;font-weight:bold;">{{ averageAssessments?.averageMonth }}</div>
                    </span>
                    <span v-if="averageAssessments?.averageQuarter > 0"
                          class="flex gap-2">
                        <div style="margin-left:30px;color:green">{{ __('Average assessment per quarter') }}:</div>
                        <div style="color:red;font-weight:bold;">{{ averageAssessments?.averageQuarter }}</div>
                    </span>
                </div>
            </template>

            <template #crud="formProps">
                <WorkerForm :formProps="formProps"> </WorkerForm>
            </template>
            <template #search="searchProps">
                <WorkerSearch :searchProps="searchProps"> </WorkerSearch>
            </template>
        </v-view>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useForm, usePage } from '@inertiajs/vue3'
import Button from 'primevue/button';
import WorkerForm from "./WorkerForm.vue";
import WorkerSearch from "./WorkerSearch.vue";
import ConfirmDialog from '@pages/Crud/ConfirmDialog.vue'
import Dialog from 'primevue/dialog';
import FormCalendar from '@crud/FormCalendar.vue'
import FormInputToggleButton from '@crud/FormInputToggleButton.vue'
import GenerateSheetsForm from "./GenerateSheetsForm.vue";
import { __, screenMessage, convertDate, getFromRoute, getStoreValue, getServerValue, setStoreValue } from "@pages/Helper/utils.js";
import { rowButtonAction } from "@pages/Helper/crud_utils.js";
import { warningIcon } from '@helper/constants.js'
//import { getSessionPeriod } from "@pages/Helper/custom.js";

const props = defineProps({
    data: Object,
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    tooltipColumns: Object,
    initialSort: Object,
    buttonsVisibility: Object,
    versionDate: String,
    minDate: String,
    canCreateUsers: Boolean,
    canGenerateSheets: Boolean,
    canApproveSheets: Boolean,
    canPassToApproveSheets: Boolean,
    canPassToAcceptSheets: Boolean,
    canGetSapWorkers: Boolean,
    averageAssessments: Object,
    summarySheetsAndWorkers: String,
    showActiveWorkers: Boolean,
});

const isLoading = ref(false)
const isConfirmationGetSapWorkersOpen = ref(false)
const isConfirmationAddToUsersOpen = ref(false)
const isConfirmationApproveSheetsOpen = ref(false)
const isConfirmationPassToApproveSheetsOpen = ref(false)
const isConfirmationPassToAcceptSheetsOpen = ref(false)

const recordSelectedToPass = ref(null)
const approveSheetsParameter = ref(null)
const passToApproveSheetsParameter = ref(null)
const passToAcceptSheetsParameter = ref(null)

const isGenerateSheetsModalOpen = ref(false)
const generateSheetsFormProps = ref({})
const workers_on_day = ref(props.versionDate)
const show_active_workers = ref(props.showActiveWorkers)

const whoAmICountingOpen = ref(false)
const interpretedSheetAndWorkersCounting = ref(null)

const premiumPeriod = ref(null)
const page = usePage()
const images = import.meta.globEager('/resources/images/**')

//------------------------------------------------------------------------------

setStoreValue('currentDate', page.props.currentDate)

//------------------------------------------------------------------------------

watch(() => props.showActiveWorkers, (newValue) => {
    show_active_workers.value = newValue;
});

//------------------------------------------------------------------------------

const renderColumnValue = (field, value, data) => {
    switch (field) {
        case 'last_name':
            //if ((data.is_working && data.dismissal_date) || (data.is_premium && (data.premium_from_date || data.premium_to_date))) {
            if (data.is_about_to_change) {
                const key = `/resources/images/${warningIcon}`
                const img = images[key]?.default
                return "<span style='display:flex;align-items:center;' title='" + __('Worker will soon be dismissed or no longer be subject of premium system') + "'>" + value + ' ' + "<img style='padding-left:10px;' src='" + img + "' /></span>";
            } else {
                return data.is_master_subordinate ? '<span style="color:blue">' + value + '</span>' : value;
            }
        case "is_premium":
            return value == 1 ? '<span class="flex justify-content-center"><i class="fa-solid fa-coins" style="font-size:20px;color:royalblue;"></i></span>' : "";
        case "is_working":
            //return value == 1 ? '<span class="flex justify-content-center"><i class="fa-solid fa-person-digging" style="font-size:20px;color:maroon;"></i></span>' : "";
            return value == 1 ? __('Yes') : __('No');
        default:
            return data.is_master_subordinate ? '<span style="color:blue">' + value + '</span>' : value;
    }
};

//------------------------------------------------------------------------------

const renderColumnAction = (item, rowData, column) => {
    let newItem = {}

    if (!rowData.is_subject_to_premium_system) {
        newItem.tooltip = __('Not subject to premium system')
        newItem.prohibited = true
        newItem.name = '<i class="fa-solid fa-ban" style="color:black;"></i>'
    } else {
        newItem.prohibited = false
        newItem.tootip = item.tooltip
        newItem.name = item.name
    }

    return { ...newItem }
}

//------------------------------------------------------------------------------

const workersOnDayChanged = async (value, formProps) => {
    if (value) {
        const workersOnDayDate = convertDate(value)
        await formProps.getLazyData({ page: 1, versionDate: workersOnDayDate })
    }
}

//------------------------------------------------------------------------------

const workersOnDayInit = async (formProps) => {
    //await nextTick()
    const value = await getServerValue('versionDate')
    if (value) {
        const workersOnDayDate = convertDate(value.versionDate)
        workers_on_day.value = workersOnDayDate
    }
}

//------------------------------------------------------------------------------

const workersOnDayCleared = async (value, formProps) => {
    await formProps.getLazyData({ page: 1, versionDate: null });
}

//------------------------------------------------------------------------------

const actionGetSapWorkersButton = async () => {
    isLoading.value = true
    const response = await getFromRoute('worker-get-from-sap', {}, 'get')
    isLoading.value = false

    if (response.status == 'success') {
        screenMessage({ message: response.message, title: "Information", type: "info", position: "center", timeout: 10 });
    } else {
        screenMessage({ message: response.message, title: "Information", type: "error", position: "center", timeout: 15 });
    }
}

//------------------------------------------------------------------------------

const actionAddToUsersButton = (formProps) => {
    const records = formProps.selectedRows;
    let ids = [];
    for (let record of records) {
        ids.push(record.id);
    }

    const formData = { selectedRows: ids }
    useForm(formData).post(route('user-addfromworkers'), {
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onError: (errors) => {
            console.error(errors)
        },
        onSuccess: () => {
            const message = {
                message: "Selected workers was added to users database successfully",
                title: "Information",
                type: "info",
                position: "center",
                timeout: 5,
            };
            screenMessage(message);
        },
        onFinish: () => {
            formProps.clearSelection()
            formProps.getLazyData()
        }
    })
}

//------------------------------------------------------------------------------

const openConfirmationGetSapWorkersWindow = () => {
    isConfirmationGetSapWorkersOpen.value = true
}

//------------------------------------------------------------------------------

const openConfirmationAddToUserWindow = (formProps) => {
    if (!Array.isArray(formProps.selectedRows) || formProps.selectedRows.length === 0) {
        const message = {
            message: "No worker has been selected",
            title: "Worker selection error",
            type: "error",
            position: "center",
            timeout: 5,
        };
        screenMessage(message);
        return false;
    }

    recordSelectedToPass.value = formProps
    isConfirmationAddToUsersOpen.value = true
}

//------------------------------------------------------------------------------

const selectCheckboxConditions = (data) => {
    data = data.filter((elem) => !(elem.is_master && elem.is_passed_by_master))
    return data
}

//------------------------------------------------------------------------------

const openGenerateSheetsWindow = (formProps) => {
    generateSheetsFormProps.value = formProps
    isGenerateSheetsModalOpen.value = true
}

//------------------------------------------------------------------------------

const openConfirmationApproveSheetsWindow = (formProps) => {
    approveSheetsParameter.value = formProps
    getPremiumPeriod()
    premiumPeriod.value ? isConfirmationApproveSheetsOpen.value = true : noPremiumPeriodInfo()
}

//------------------------------------------------------------------------------

const openConfirmationPassToApproveSheetsWindow = (formProps) => {
    passToApproveSheetsParameter.value = formProps
    getPremiumPeriod()
    premiumPeriod.value ? isConfirmationPassToApproveSheetsOpen.value = true : noPremiumPeriodInfo()
}

//------------------------------------------------------------------------------

const openConfirmationPassToAcceptSheetsWindow = (formProps) => {
    passToAcceptSheetsParameter.value = formProps
    getPremiumPeriod()
    premiumPeriod.value ? isConfirmationPassToAcceptSheetsOpen.value = true : noPremiumPeriodInfo()
}

//------------------------------------------------------------------------------

const approveSheets = async (formProps) => {
    const response = await getFromRoute('sheet-approve', { premiumPeriod: premiumPeriod.value }, 'post')
    formProps.getLazyData()

    if (response.status == 'success') {
        screenMessage({ message: response.message, title: "Information", type: "info", position: "center", timeout: 5 });
    } else {
        screenMessage({ message: response.message, title: "Information", type: "error", position: "center", timeout: 10 });
    }
}

//------------------------------------------------------------------------------

const passToApproveSheets = async (formProps) => {
    const response = await getFromRoute('sheet-pass-approve', { premiumPeriod: premiumPeriod.value }, 'post')
    formProps.getLazyData()

    if (response.status == 'success') {
        screenMessage({ message: response.message, title: "Information", type: "info", position: "center", timeout: 5 });
    } else {
        screenMessage({ message: response.message, title: "Information", type: "error", position: "center", timeout: 10 });
    }
}

//------------------------------------------------------------------------------

const passToAcceptSheets = async (formProps) => {
    const records = formProps.selectedRows ?? [];
    let ids = [];
    for (let record of records) {
        ids.push(record.id);
    }

    const response = await getFromRoute('sheet-pass-accept', { premiumPeriod: premiumPeriod.value, selectedRows: ids }, 'post')
    formProps.clearSelection()
    formProps.getLazyData()

    if (response.status == 'success') {
        screenMessage({ message: response.message, title: "Information", type: "info", position: "center", timeout: 5 });
    } else {
        screenMessage({ message: response.message, title: "Information", type: "error", position: "center", timeout: 10 });
    }
}

//------------------------------------------------------------------------------

const getPremiumPeriod = async () => {
    premiumPeriod.value = getStoreValue('premiumPeriod')
}

//------------------------------------------------------------------------------

const noPremiumPeriodInfo = () => {
    const message = {
        message: "No premium period is selected",
        title: "Premium period error",
        type: "error",
        position: "center",
        timeout: 5,
    };
    screenMessage(message);
}

//------------------------------------------------------------------------------

const whoAmICounting = () => {
    interpretedSheetAndWorkersCounting.value = props.summarySheetsAndWorkers
    whoAmICountingOpen.value = true
}

//------------------------------------------------------------------------------

const setWorkersViewState = async (e, formProps) => {
    let state = (e.target.value.toLowerCase() !== 'true')
    await getFromRoute('worker-set-view-state', { state }, 'get')
    show_active_workers.value = state
    formProps.getLazyData()
}

//------------------------------------------------------------------------------

</script>

<style lang="scss" scoped></style>
