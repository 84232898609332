<template>
    <div>
        <Button v-ripple
                v-tooltip.top="__(tooltip)"
                @click="backToRoute"
                :method="method"
                class="back-link">
            <i class="back-link-icon fa-solid fa-circle-left"></i>
        </Button>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3'
import Button from 'primevue/button';
import { __, checkRoute } from '@pages/Helper/utils.js'

const props = defineProps({
    tooltip: {
        type: String,
        default: ''
    },
    routeName: {
        type: String,
        default: 'route-not-exist'
    },
    method: {
        type: String,
        default: 'get'

    },
    customData: {
        type: Object,
        default: {}
    }
})

const backToRoute = () => {
    router.visit(checkRoute(props.routeName, props.customData, __('Selected module not exists')))
}

</script>

<style lang="scss" scoped></style>
