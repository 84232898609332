<template>
    <v-form :name="searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormDropdown fieldName="currentPeriodSheet"
                                  label="Assessment period"
                                  showClear
                                  size="8"
                                  :filtering="false"
                                  :options="select?.premium_periods"
                                  v-model:field="search.currentPeriodSheet" />
                    <FormInputText fieldName="currentYearSheet"
                                   label="Year"
                                   size="4"
                                   v-model:field="search.currentYearSheet" />

                    <FormInputText fieldName="deptSheet"
                                   label="Department"
                                   size="4"
                                   v-model:field="search.deptSheet" />
                    <FormInputText fieldName="sectorSheet"
                                   label="Section"
                                   size="4"
                                   v-model:field="search.sectorSheet" />
                    <FormInputText fieldName="unitSheet"
                                   label="Division"
                                   size="4"
                                   v-model:field="search.unitSheet" />
                    <FormInputText fieldName="officeSheet"
                                   label="Position"
                                   size="12"
                                   v-model:field="search.officeSheet" />
                    <FormDropdown fieldName="workerSheet"
                                  label="Position type"
                                  size="2"
                                  showClear
                                  :options="select?.archive_worker_types"
                                  v-model:field="search.workerSheet" />
                    <FormInputText fieldName="symbolPremiumSheet"
                                   label="Premium group"
                                   size="4"
                                   v-model:field="search.symbolPremiumSheet" />
                    <FormInputText fieldName="evaluator"
                                   label="Egzaminers"
                                   size="6"
                                   v-model:field="search.evaluator" />
                </div>
            </TabPanel>
        </TabView>

        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormInputText from '@crud/FormInputText.vue'
import FormDropdown from "@crud/FormDropdown.vue";
import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})

const autoSearch = ref(false)
const options = ["premium_periods", "archive_worker_types"]
const select = ref(null)

let search = ref({})
prepareSearch(search, props, options, select)

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
