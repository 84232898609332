<template>
    <v-view :tableData="tableData"
            :tableProps="tableProps"
            :modalProps="modalProps"
            :columns="columns"
            :routes="routes"
            :initialSort="initialSort"
            :rowButtonAction="rowButtonAction"
            :buttonsVisibility="buttonsVisibility"
            :customData="customData"
            v-model:selectedRows="selectedRows">
        <template #buttons>
            <BackButton tooltip="Back to workers list"
                        @click="resetPaginatorPage"
                        :routeName="previousRoute" />
        </template>

        <template #crud="formProps">
            <ArchiveSheetForm :formProps="formProps">
            </ArchiveSheetForm>
        </template>

        <template #search="searchProps">
            <ArchiveSheetSearch :searchProps="searchProps">
            </ArchiveSheetSearch>
        </template>

    </v-view>
</template>

<script setup>
import ArchiveSheetSearch from './ArchiveSheetSearch.vue'
import ArchiveSheetForm from './ArchiveSheetForm.vue'
import BackButton from '@pages/Crud/BackButton.vue';
import { ref } from "vue";
import { rowButtonAction } from '@pages/Helper/crud_utils.js'
import { getAppName } from '@helper/utils.js'

const selectedRows = ref([])

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    tooltipColumns: Object,
    initialSort: Object,
    buttonsVisibility: Object,
    previousRoute: String,
    idEmployee: String,
})

const previousRoute = ref(props.previousRoute ?? 'archive-index')
const customData = ref({ idEmployee: props.idEmployee })

// -----------------------------------------------------

const resetPaginatorPage = () => {
    const appName = getAppName();
    const storageKey = appName + "-" + props.routes.index;
    const savedTableSetting = JSON.parse(sessionStorage.getItem(storageKey)) ?? {};

    savedTableSetting.first = 1;
    sessionStorage.setItem(storageKey, JSON.stringify(savedTableSetting));
}

//------------------------------------------------------------------------------

</script>

<style lang="scss" scoped></style>
