<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="formProps.formName">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="sheet_number_preview"
                                   label="Sheet number"
                                   size="2"
                                   :isFormDisabled="true"
                                   v-model:field="crud.sheet_number_preview" />
                    <FormDropdown fieldName="statusSheet"
                                  label="Status"
                                  size="2"
                                  :isFormDisabled="true"
                                  :options="select?.archive_statuses"
                                  v-model:field="crud.statusSheet" />
                    <FormCalendar fieldName="dateSheet"
                                  label="Sheet creation date"
                                  size="2"
                                  :isFormDisabled="true"
                                  v-model:field="crud.dateSheet" />
                    <FormInputText fieldName="worker"
                                   label="Worker"
                                   size="6"
                                   :isFormDisabled="true"
                                   v-model:field="crud.worker" />
                </div>
                <div class="grid">
                    <FormInputText fieldName="officeSheet"
                                   label="Position"
                                   size="6"
                                   :isFormDisabled="true"
                                   v-model:field="crud.officeSheet" />
                    <FormInputText fieldName="deptSheet"
                                   label="Department"
                                   size="1"
                                   :isFormDisabled="true"
                                   v-model:field="crud.deptSheet" />
                    <FormInputText fieldName="sectorSheet"
                                   label="Division"
                                   size="1"
                                   :isFormDisabled="true"
                                   v-model:field="crud.sectorSheet" />
                    <FormInputText fieldName="unitSheet"
                                   label="Section"
                                   size="1"
                                   :isFormDisabled="true"
                                   v-model:field="crud.unitSheet" />
                    <FormInputText fieldName="symbolPremiumSheet"
                                   label="Premium group"
                                   size="1"
                                   :isFormDisabled="true"
                                   v-model:field="crud.symbolPremiumSheet" />
                    <FormInputText fieldName="assessment_period"
                                   label="Assessment period"
                                   size="2"
                                   :isFormDisabled="true"
                                   v-model:field="crud.assessment_period" />
                </div>


                <span v-if="crud.is_premium_right">
                    <div class="w-full sheet-section-title">{{ __('Premium right') }}</div>
                    <div class="grid sheet-box-divider">
                        <FormInputToggleButton fieldName="rightSheet"
                                               label="Worker meet expectations"
                                               :isFormDisabled="true"
                                               v-model:field="crud.rightSheet" />
                    </div>
                    <div v-if="!crud.is_premium_right">
                        <FormTextarea fieldName="rightReasonSheet"
                                      label="Reason for lack of premium right"
                                      :isFormDisabled="true"
                                      :rows="4"
                                      :cols="30"
                                      v-model:field="crud.rightReasonSheet" />
                    </div>
                </span>

                <div v-if="crud.hr_event"
                     class="w-full sheet-section-title">{{ __('HR Events') }}
                    <div class="grid sheet-box-divider">
                        <FormInputText fieldName="hr_event"
                                       label="HR Event"
                                       size="2"
                                       :isFormDisabled="true"
                                       v-model:field="crud.hr_event" />
                    </div>
                </div>

                <div class="grid">
                    <div v-if="crud.is_premium_right">
                        <div v-if="crud.is_quality_criterions">
                            <div class="w-full sheet-section-title">{{ __('Quality criterions') }}</div>
                            <div class="grid sheet-box-divider"
                                 style="padding:0 10px 0 10px">
                                <FormRepeaterTable v-model:record="crud.sheetCriterions"
                                                   :keys="{ 'weightSheetCriterion': 0, 'assesSheetCriterion': 0, 'resultSheetCriterion': 0, 'justificationSheetCriterion': null }"
                                                   :disabled="true"
                                                   :isFirstRowEmpty="false"
                                                   :isCopyButton="false"
                                                   :isDeleteButton="false"
                                                   :isAddButton="false"
                                                   cellStyle=""
                                                   cellClass="sheet-cells"
                                                   repeaterStyle="margin-top:5px;"
                                                   name="sheetCriterions"
                                                   :title="[__('Criterium name'), __('Weight'), __('Assessment'), __('Result'), __('Justification')]">
                                    <template #repeat="slotProps">

                                        <td :class="slotProps.cellClass">
                                            <FormInputText :style="{ width: '180px' }"
                                                           fieldName="nameSheetCriterion"
                                                           :isFormDisabled="true"
                                                           :inRepeater="true"
                                                           :filtering="false"
                                                           v-model:field="slotProps.record.nameSheetCriterion" />
                                        </td>
                                        <td :class="slotProps.cellClass">
                                            <FormInputText :style="{ width: '100px' }"
                                                           fieldName="weightSheetCriterion"
                                                           class="weight-criterion"
                                                           :isFormDisabled="true"
                                                           :inRepeater="true"
                                                           :filtering="false"
                                                           v-model:field="slotProps.record.weightSheetCriterion" />
                                        </td>
                                        <td :class="slotProps.cellClass">
                                            <FormInputText :style="{ width: '100px' }"
                                                           fieldName="assesSheetCriterion"
                                                           class="weight-criterion"
                                                           :isFormDisabled="true"
                                                           :inRepeater="true"
                                                           :filtering="false"
                                                           v-model:field="slotProps.record.assesSheetCriterion" />
                                        </td>
                                        <td :class="slotProps.cellClass">
                                            <FormInputText :style="{ width: '100px' }"
                                                           fieldName="result"
                                                           class="weight-criterion"
                                                           :isFormDisabled="true"
                                                           :inRepeater="true"
                                                           :filtering="false"
                                                           v-model:field="slotProps.record.resultSheetCriterion" />
                                        </td>
                                        <td :class="slotProps.cellClass">
                                            <FormInputText :style="{ width: '100px' }"
                                                           fieldName="justificationSheetCriterion"
                                                           class="weight-criterion"
                                                           :isFormDisabled="true"
                                                           :inRepeater="true"
                                                           :filtering="false"
                                                           v-model:field="slotProps.record.justificationSheetCriterion" />
                                        </td>
                                    </template>
                                    <template #footer="slotProps">
                                        <td :class="slotProps.cellClass"></td>
                                        <td :class="slotProps.cellClass"
                                            class="sheet-sum">{{ criterionsWeigthSum }}%</td>
                                        <td :class="slotProps.cellClass"></td>
                                        <td :class="slotProps.cellClass"
                                            class="sheet-sum">{{ criterionsResultSum }}%</td>
                                        <td :class="slotProps.cellClass"></td>
                                    </template>
                                </FormRepeaterTable>
                            </div>
                        </div>

                        <div v-if="crud.is_premium_targets">
                            <div class="w-full sheet-section-title">{{ __('Premium targets') }}</div>
                            <div class="grid sheet-box-divider"
                                 style="padding:0 10px 0 10px">
                                <FormRepeaterTable v-model:record="crud.sheetTargets"
                                                   :keys="{ 'weightSheetCriterion': 0, 'assesSheetCriterion': 0, 'resultSheetCriterion': 0, 'justificationSheetCriterion': null }"
                                                   :disabled="true"
                                                   :isFirstRowEmpty="false"
                                                   :isCopyButton="false"
                                                   :isDeleteButton="false"
                                                   :isAddButton="false"
                                                   cellStyle=""
                                                   cellClass="sheet-cells"
                                                   repeaterStyle="margin-top:5px;"
                                                   name="sheetTargets"
                                                   :title="[__('Type'), __('Criterium name'), __('Weight'), __('Assessment'), __('Result'), __('Justification')]">
                                    <template #repeat="slotProps">
                                        <td :class="slotProps.cellClass">
                                            <FormInputText :style="{ width: '180px' }"
                                                           fieldName="typeTargetSheetCriterion"
                                                           :isFormDisabled="true"
                                                           :inRepeater="true"
                                                           :filtering="false"
                                                           v-model:field="slotProps.record.typeTargetSheetCriterion" />
                                        </td>
                                        <td :class="slotProps.cellClass">
                                            <FormInputText :style="{ width: '180px' }"
                                                           fieldName="nameSheetCriterion"
                                                           :isFormDisabled="true"
                                                           :inRepeater="true"
                                                           :filtering="false"
                                                           v-model:field="slotProps.record.nameSheetCriterion" />
                                        </td>
                                        <td :class="slotProps.cellClass">
                                            <FormInputText :style="{ width: '100px' }"
                                                           fieldName="weightSheetCriterion"
                                                           class="weight-criterion"
                                                           :isFormDisabled="true"
                                                           :inRepeater="true"
                                                           :filtering="false"
                                                           v-model:field="slotProps.record.weightSheetCriterion" />
                                        </td>
                                        <td :class="slotProps.cellClass">
                                            <FormInputText :style="{ width: '100px' }"
                                                           fieldName="assesSheetCriterion"
                                                           class="weight-criterion"
                                                           :isFormDisabled="true"
                                                           :inRepeater="true"
                                                           :filtering="false"
                                                           v-model:field="slotProps.record.assesSheetCriterion" />
                                        </td>
                                        <td :class="slotProps.cellClass">
                                            <FormInputText :style="{ width: '100px' }"
                                                           fieldName="result"
                                                           class="weight-criterion"
                                                           :isFormDisabled="true"
                                                           :inRepeater="true"
                                                           :filtering="false"
                                                           v-model:field="slotProps.record.resultSheetCriterion" />
                                        </td>
                                        <td :class="slotProps.cellClass">
                                            <FormInputText :style="{ width: '100px' }"
                                                           fieldName="justificationSheetCriterion"
                                                           class="weight-criterion"
                                                           :isFormDisabled="true"
                                                           :inRepeater="true"
                                                           :filtering="false"
                                                           v-model:field="slotProps.record.justificationSheetCriterion" />
                                        </td>

                                    </template>
                                    <template #footer="slotProps">
                                        <td :class="slotProps.cellClass"></td>
                                        <td :class="slotProps.cellClass"
                                            class="sheet-sum">{{ targetsWeigthSum }}%</td>
                                        <td :class="slotProps.cellClass"></td>
                                        <td :class="slotProps.cellClass"
                                            class="sheet-sum">{{ targetsResultSum }}%</td>
                                        <td :class="slotProps.cellClass"></td>
                                    </template>
                                </FormRepeaterTable>
                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :validation="validation"
                         :buttonsVisibility="buttonsVisibility"
                         v-model:isFormDisabled="isFormDisabled">
                <template #after-in-show="buttonProps">
                    <CustomButton name="printSheet"
                                  :buttonProps="buttonProps"
                                  :buttonsVisibility="buttonsVisibility"
                                  :click="() => printSheet()"
                                  style="margin:0 5px"
                                  severity="info">
                        {{ __('Print') }}
                    </CustomButton>
                </template>
            </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { validation, onFilterService, initForm } from '@pages/Helper/crud_utils.js'
import FormDropdown from '@crud/FormDropdown.vue'
import FormInputText from '@crud/FormInputText.vue'
import FormInputToggleButton from '@crud/FormInputToggleButton.vue'
import FormCalendar from '@crud/FormCalendar.vue'
import FormTextarea from '@crud/FormTextarea.vue'
import FormButtons from '@crud/FormButtons.vue'
import FormRepeaterTable from '@crud/FormRepeaterTable.vue'
import CustomButton from '@crud/CustomButton.vue'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(false)
const assessmentPeriod = ref(null)
const premiumRight = ref(null)

const criterionsWeigthSum = ref(0)
const criterionsResultSum = ref(0)
const targetsWeigthSum = ref(0)
const targetsResultSum = ref(0)

const options = ["archive_statuses"];
const select = ref(null);

const crud = ref({})
const buttonsVisibility = ref({})

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, options, select, buttonsVisibility)
    assessmentPeriod.value = crud.value.currentPeriodSheet > '300' ? __('quarter') : __('month')
    premiumRight.value = crud.value.rightSheet == 'N' ? __('no') : __('yes')

    criterionsWeigthSum.value = crud.value.sheetCriterions.reduce((acc, item) => acc + parseInt(item.weightSheetCriterion), 0)
    criterionsResultSum.value = crud.value.sheetCriterions.reduce((acc, item) => acc + parseInt(item.resultSheetCriterion), 0)

    targetsWeigthSum.value = crud.value.sheetTargets.reduce((acc, item) => acc + parseInt(item.weightSheetCriterion ?? 0), 0)
    targetsResultSum.value = crud.value.sheetTargets.reduce((acc, item) => acc + parseInt(item.resultSheetCriterion ?? 0), 0)
})

// -----------------------------------------------------

const printSheet = async () => {
    isLoading.value = true;
    const response = await getFromRoute('sheet-print', { 'sheet_id': crud.value.id }, 'post')
    printReport(response)
    isLoading.value = false;
}

// --------------------------------------------------------------------------------------------------------------------------------------------

</script>

<style lang="scss" scoped></style>
