<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="props.formProps.formName">
        <ConfirmDialog header="Confirm operation"
                       question="Do you want to send reset password link?"
                       v-model="isConfirmationOpen"
                       :operation="sendResetPasswordLink" />

        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="last_name"
                                   label="Last name"
                                   size="3"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   :isFocus="true"
                                   v-model:field="crud.last_name" />

                    <FormInputText fieldName="first_name"
                                   label="First name"
                                   size="3"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.first_name" />

                    <FormInputText fieldName="email"
                                   label="Email"
                                   size="3"
                                   :validation="['required', 'email']"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.email" />

                    <FormDropdown fieldName="worker_id"
                                  label="Worker"
                                  size="3"
                                  showClear
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.workers"
                                  :filter="(event) => onFilter(event, 'workers')"
                                  v-model:field="crud.worker_id" />
                </div>
                <div class="grid">
                    <FormInputMask fieldName="phone"
                                   label="Phone"
                                   size="3"
                                   mask="(+99) 999-999-999"
                                   placeholder="(+99) 999-999-999"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.phone" />

                    <FormInputToggleButton fieldName="is_locked"
                                           label="Is user locked?"
                                           size="3"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_locked" />

                    <FormInputToggleButton fieldName="is_active"
                                           label="Is active user?"
                                           size="3"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_active" />

                    <FormInputToggleButton fieldName="is_password_policy"
                                           label="Is password policy?"
                                           size="3"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_password_policy" />
                </div>
                <div class="grid">
                    <FormRadioButton fieldName="language"
                                     label="Language"
                                     size="2"
                                     :data="languages"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.language" />

                    <FormRadioButton fieldName="menu"
                                     label="Menu"
                                     size="2"
                                     :data="menuTypes"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.menu" />
                    <FormInputToggleButton v-if="crud.menu == 'V'"
                                           fieldName="is_expandable_menu"
                                           label="Hiddenable side menu"
                                           size="2"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_expandable_menu" />
                    <FormStaticText v-else
                                    label=""
                                    size="2" />

                    <FormStaticText label="Last login"
                                    size="3"
                                    :style="{ 'font-weight': 'bold', color: 'blue' }"
                                    :content="crud?.last_login_time" />

                    <FormStaticText label="Last logout"
                                    size="3"
                                    :style="{ 'font-weight': 'bold', color: 'blue' }"
                                    :content="crud?.last_logout_time" />
                </div>
                <div class="grid">
                    <FormMultiselect fieldName="roles"
                                     label="Role"
                                     size="6"
                                     showClear
                                     :isFormDisabled="isFormDisabled"
                                     :options="select?.roles"
                                     :filter="(event) => onFilter(event, 'roles')"
                                     v-model:field="crud.roles" />

                    <FormPassword fieldName="password"
                                  label="Password"
                                  size="3"
                                  :isFormDisabled="isFormDisabled"
                                  v-model:field="crud.password" />

                    <FormPassword fieldName="retype_password"
                                  label="Retype password"
                                  size="3"
                                  :isFormDisabled="isFormDisabled"
                                  v-model:field="retypePassword" />
                </div>
                <div class="grid">
                    <FormInputToggleButton fieldName="is_login_behalf"
                                           label="Possibility to login to foreign accounts"
                                           size="3"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_login_behalf" />

                    <FormInputToggleButton fieldName="is_system_user"
                                           label="System account"
                                           size="3"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_system_user" />

                    <FormInputToggleButton fieldName="is_final_approval"
                                           label="Is final approval?"
                                           size="3"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_final_approval" />

                    <FormInputToggleButton fieldName="is_submit_to_approval"
                                           label="Is submit approval?"
                                           size="3"
                                           :isFormDisabled="isFormDisabled"
                                           v-model:field="crud.is_submit_to_approval" />
                </div>
                <div class="grid">
                    <FormRadioButton fieldName="page_length"
                                     label="Page length"
                                     size="2"
                                     :data="select?.page_length"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.page_length" />
                    <FormMultiselect v-if="multiModuleApp"
                                     fieldName="module_symbols"
                                     label="Modules"
                                     size="3"
                                     showClear
                                     :isFormDisabled="isFormDisabled"
                                     :options="select?.modules"
                                     v-model:field="crud.modules" />
                    <FormEditor fieldName="description"
                                label="Description"
                                :editorStyle="{ height: '120px' }"
                                :isFormDisabled="isFormDisabled"
                                v-model:field="crud.description" />

                </div>
                <div v-if="formProps.editMode"
                     class="grid">
                    <FormInputText fieldName="account_name"
                                   label="Account name"
                                   size="3"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.account_name" />
                </div>
            </TabPanel>

            <TabPanel v-if="crud.log"
                      :header="__('Changelog')"
                      headerClass="form-tab-header">
                <ChangeLog :log="crud?.log" />
            </TabPanel>
        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :beforeSave="beforeSave"
                         :validation="validation"
                         :buttonsVisibility="buttonsVisibility"
                         v-model:isFormDisabled="isFormDisabled">
                <template #after-in-show>
                    <Button @click.stop="openConfirmationWindow()"
                            style="background-color:cadetblue;margin:0 7px 0 25px"
                            severity="info">
                        <i class="fa-solid fa-unlock-keyhole"
                           style="margin-right:5px;font-size:18px"></i>{{ __('Send reset password link') }}</Button>
                </template>
            </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted, onBeforeMount } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import { validation, initForm } from "@pages/Helper/crud_utils.js";
import FormInputText from "@crud/FormInputText.vue";
import FormPassword from "@crud/FormPassword.vue";
import FormDropdown from "@crud/FormDropdown.vue";
import FormRadioButton from "@crud/FormRadioButton.vue";
import FormMultiselect from "@crud/FormMultiselect.vue";
import FormButtons from "@crud/FormButtons.vue";
import FormEditor from "@crud/FormEditor.vue";
import FormInputMask from "@crud/FormInputMask.vue";
import FormInputToggleButton from "@crud/FormInputToggleButton.vue";
import FormStaticText from "@crud/FormStaticText.vue";
import ConfirmDialog from "@crud/ConfirmDialog.vue";
import ChangeLog from "@crud/ChangeLog.vue";
import { __, screenMessage, getConfigOptions, getFromRoute } from "@pages/Helper/utils.js";
import { onFilterService } from "@pages/Helper/crud_utils.js";

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
});
console.log(props.formProps)
const isLoading = ref(true);
const isFormDisabled = ref(false);

const options = ["workers", "roles", "page_length", "modules"];
const select = ref(null);
const crud = ref({});

const retypePassword = ref("");
const multiModuleApp = ref(false)
const isConfirmationOpen = ref(false)
const buttonsVisibility = ref(null);

// -----------------------------------------------------

onBeforeMount(async () => {
    multiModuleApp.value = await getConfigOptions('multiModuleApp')
});

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, options, select, buttonsVisibility, null, ['roles', 'modules']);
});

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading, null, null, ['roles', 'modules']);
}

// -----------------------------------------------------

const beforeSave = (sourceForm) => {
    if (sourceForm.password !== retypePassword.value) {
        const message = {
            message: "Passwords not match",
            title: "Password error",
            type: "error",
            position: "center",
            timeout: 5,
        };
        screenMessage(message);
        return false;
    }
    return sourceForm;
};

// -----------------------------------------------------

const sendResetPasswordLink = async () => {
    const retValue = await getFromRoute('password-send-link', { 'user_id': crud.value?.id }, 'post');
    const message = { message: "", title: "", type: "Information", position: "center", timeout: 5 };

    if (retValue?.status) {
        message.message = "Reset password link was sent successfully"
        message.type = "info"
    } else {
        message.message = "Reset password link was not sent"
        message.type = "error"
    }

    screenMessage(message);
};

// -----------------------------------------------------

const openConfirmationWindow = () => {
    isConfirmationOpen.value = true
}

//------------------------------------------------------------------------------

const menuTypes = [
    { label: 'poziome', value: 'H' },
    { label: 'pionowe', value: 'V' },
]

// -----------------------------------------------------

const languages = [
    { label: 'polski', value: 'pl' },
    { label: 'angielski', value: 'en' },
]

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
